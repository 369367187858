import classNames from 'classnames';
import React, { FC } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useApp } from 'src/@context/App';
import AnimateHeightChange from './AnimateHeightChange';
import useStyles from './SubmenuItem.styles';

const SubmenuItem: FC<SubmenuDto & { forceActive?: boolean }> = ({
  Title,
  Url,
  UID,
  Text,
  forceActive = false,
}) => {
  const cls = useStyles();
  const location = useLocation();

  const { menuOpen, toggleMenu, app } = useApp();
  const pathSegments = location.pathname.split('/');
  const lastSegment = pathSegments[pathSegments.length - 1];

  if (!app) {
    return null;
  }

  return (
    <>
      <NavLink
        className={classNames(cls.item, { [cls.itemActive]: forceActive })}
        activeClassName={cls.itemActive}
        to={Url}
        onClick={() => {
          if (menuOpen) {
            toggleMenu(false);
          }
        }}
      >
        {Title}
      </NavLink>
      <AnimateHeightChange
        expanded={!!Text && (forceActive || lastSegment === UID)}
      >
        <div className={cls.sideText}>
          <div
            className={cls.sideTextInner}
            dangerouslySetInnerHTML={{ __html: Text }}
          />
        </div>
      </AnimateHeightChange>
      <AnimateHeightChange
        expanded={
          (forceActive || pathSegments.includes(UID)) &&
          !!app.PagesSideText[lastSegment]
        }
      >
        <div className={cls.sideText}>
          <div
            className={cls.sideTextInner}
            dangerouslySetInnerHTML={{ __html: app.PagesSideText[lastSegment] }}
          />
        </div>
      </AnimateHeightChange>
    </>
  );
};

export default SubmenuItem;
