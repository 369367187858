import React from 'react';
import { useResponsive } from 'src/@context/Responsive';
import { NextLanguage } from 'src/utils/Languages';
import { useApp } from 'src/@context/App';
import useStyles from './MainMenu.styles';
import MenuItem from './MenuItem';

const MainMenu = () => {
  const cls = useStyles();
  const { app, menuOpen } = useApp();
  const { steps } = useResponsive();

  if (!app) {
    return null;
  }
  const { MainMenu: mainMenu } = app;

  return (
    <nav id='main-menu' className={cls.root} data-open={menuOpen}>
      {steps.includes('Mobile') && (
        <div className={cls.langWrapper}>
          <a
            aria-label='Switch lamguage'
            href={`?switch-lang=${NextLanguage}`}
            className={cls.langLink}
          >
            {NextLanguage}
          </a>
        </div>
      )}
      {mainMenu.map((m, i) => (
        <MenuItem key={m.Url} isFirst={i === 0} {...m} />
      ))}
    </nav>
  );
};

export default React.memo(MainMenu);
