import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import Image from './Image';
import useStyles from './PreviewItem.styles';

type TPreviewItem = {
  title: string;
  previewImage: PictureRef;
  url: string;
  UID: string;
  year?: string;
};

const PreviewItem: FC<TPreviewItem> = ({ title, previewImage, url, year }) => {
  const cls = useStyles();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const sizes = `(max-width: 768px) calc(100vw - 20px),
	(max-width: 1024px) calc((100vw - 300px) / 2),
	(max-width: 1280px) calc((100vw - 350px) / 2),
	(max-width: 1920px) calc((100vw - 400px) / 2),
	(max-width: 3000px) 1050px`;

  return (
    <article className={cls.root} ref={ref} data-show={inView}>
      <div className={cls.inner}>
        <h2 className={cls.title}>
          <span>
            {title} <br /> {year}
          </span>
        </h2>
        {previewImage && (
          <figure className={cls.figure}>
            <Image
              alt={previewImage.Legend}
              pictureRef={previewImage}
              sizes={sizes}
            />
          </figure>
        )}
        <Link to={url} className={cls.link} />
      </div>
    </article>
  );
};

export default PreviewItem;
