import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import Image from './Image';
import useStyles from './CollectionItemPreview.styles';

const CollectionItemPreview: FC<CollectionItemPreviewDto> = ({
  Title,
  PreviewImage,
  Url,
}) => {
  const cls = useStyles();

  return (
    <article className={cls.root}>
      <div className={cls.inner}>
        <h2 className={cls.title}>
          <span>{Title}</span>
        </h2>
        <figure className={cls.figure}>
          <Image alt={PreviewImage.Legend} pictureRef={PreviewImage} />
        </figure>
        <Link to={Url} className={cls.link} />
      </div>
    </article>
  );
};

export default CollectionItemPreview;
