import classNames from 'classnames';
import React, { FC, useEffect, useState } from 'react';
import isIosSafari from '../utils/isIosSafari';
import useStyles from './AudioPlayer.styles';

interface AudioPlayerProps {
  src: string;
  muted?: boolean;
  className?: string;
}

const AudioPlayer: FC<AudioPlayerProps> = ({
  src,
  muted = false,
  className,
}) => {
  const cls = useStyles();
  const [audioEl, setAudioEl] = useState<HTMLAudioElement | null>(null);
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    if (!audioEl) {
      return;
    }

    audioEl.addEventListener('play', () => {
      setPlaying(true);
    });

    audioEl.addEventListener('pause', () => {
      setPlaying(false);
    });
  }, [audioEl]);

  const onTogglePlay = () => {
    if (!audioEl) {
      return;
    }

    if (audioEl.paused) {
      audioEl.play();
    } else {
      audioEl.pause();
    }
  };

  useEffect(() => {
    if (!audioEl) {
      return;
    }

    if (isIosSafari()) {
      if (muted) {
        audioEl.pause();
      }
      return;
    }

    const targetVolume = muted ? 0 : 1;
    const increment = muted ? -0.15 : 0.15;
    const intervalId = setInterval(() => {
      audioEl.volume = Math.min(1, Math.max(0, audioEl.volume + increment));
      if (audioEl.volume === targetVolume) {
        clearInterval(intervalId);
        if (audioEl.volume === 0) {
          audioEl.pause();
        }
      }
    }, 200);
  }, [audioEl, muted]);

  return (
    <>
      <button
        className={classNames(cls.button, className)}
        type='button'
        onClick={onTogglePlay}
      >
        {playing ? (
          <svg
            className={cls.audioIcon}
            xmlns='http://www.w3.org/2000/svg'
            width='60'
            height='60'
            fill='none'
            viewBox='0 0 60 60'
          >
            <path
              fill='#000'
              d='M36.56 51.732c.023.425-.018.85-.12 1.264a2.244 2.244 0 01-3.72.9c-1.316-1.123-2.617-2.264-3.927-3.4-3.467-3-6.932-6.003-10.395-9.007a1.777 1.777 0 00-1.266-.47c-4.136.018-8.272.012-12.408.01a2.378 2.378 0 01-2.701-2.681V21.96a2.322 2.322 0 012.609-2.62c4.248 0 8.5.01 12.745-.02.411-.009.809-.152 1.13-.408 4.727-4.053 9.44-8.122 14.139-12.206a2.284 2.284 0 013.925 1.8l-.01 43.225zM58.023 31.96c-.052 7.007-2.713 14.665-7.912 21.449a2.278 2.278 0 01-3.214.582 2.238 2.238 0 01-.4-3.276 34.515 34.515 0 004.558-7.834 32.516 32.516 0 002.413-14.145 32.5 32.5 0 00-6.971-18.675 2.308 2.308 0 01-.4-2.57 2.25 2.25 0 013.848-.35 35.642 35.642 0 014.183 6.583c2.61 5.257 3.904 10.822 3.895 18.237z'
            />
            <path
              fill='#000'
              d='M48.265 30.388a20.395 20.395 0 01-3.02 10.633 2.24 2.24 0 01-2.316 1.156 2.072 2.072 0 01-1.856-1.78 2.714 2.714 0 01.309-1.658 16.1 16.1 0 002.308-9.594 15.072 15.072 0 00-2.29-7.027 2.308 2.308 0 011.336-3.664 2.167 2.167 0 012.46 1.229 49.13 49.13 0 012.227 5.025c.604 1.83.889 3.75.842 5.678'
            />
          </svg>
        ) : (
          <svg
            className={cls.audioIcon}
            xmlns='http://www.w3.org/2000/svg'
            width='60'
            height='60'
            fill='none'
            viewBox='0 0 60 60'
          >
            <path
              fill='#A8A8A8'
              d='M36.49 30.247V8.514a2.279 2.279 0 00-3.915-1.8c-4.673 4.046-8.9 8-13.587 12.029l17.5 18.414v-6.915M4.628 19.324a2.317 2.317 0 00-2.605 2.614v16.35a2.373 2.373 0 002.7 2.68c4.127 0 8.255.008 12.382-.009.466-.016.92.152 1.262.469 3.45 3.005 6.907 6 10.372 8.987 1.306 1.13 2.605 2.268 3.92 3.388a2.238 2.238 0 003.71-.9c.103-.412.143-.837.121-1.261v-4.388l-26.6-27.93H4.63M47.764 52.151L16.52 19.309 4.308 6.471l-.009-.005a1.256 1.256 0 00-.409-.286 1.334 1.334 0 00-.156-.032 1.166 1.166 0 00-.319-.058h-.023a1.192 1.192 0 00-.455.1l-.01.01a1.26 1.26 0 00-.408.3 1.457 1.457 0 00.03 1.941L46.006 54.12a1.193 1.193 0 001.786-.031c.12-.134.212-.29.271-.459.059-.166.088-.342.085-.518a1.428 1.428 0 00-.385-.964'
            />
          </svg>
        )}
      </button>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio
        className={cls.audio}
        loop
        autoPlay={playing}
        src={src}
        ref={setAudioEl}
      />
    </>
  );
};

export default AudioPlayer;
