/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useApp } from 'src/@context/App';
import useStyles from './Intro.styles';
import Image from './Image';
import AudioPlayer from './AudioPlayer';

const Intro: FC = () => {
  const cls = useStyles();
  const { app } = useApp();
  const [show, setShow] = useState(
    !window.sessionStorage.getItem('landingPage')
  );
  const sizes = `100vw`;

  const numImages = app?.Config?.LandingPageImages?.length || 0;
  const numImagesRef = useRef(numImages);
  numImagesRef.current = numImages;

  const [activeIndex, setActiveIndex] = useState(0);
  // Choose a random starting image
  useEffect(() => {
    if (numImages == null) {
      return;
    }

    setActiveIndex(Math.floor(Math.random() * numImages));
  }, [numImages]);
  const goToNextImage = useCallback(() => {
    const numImages = numImagesRef.current;
    setActiveIndex((index) => {
      const nextIndex = index + 1;
      if (nextIndex >= numImages) {
        return 0;
      }
      return nextIndex;
    });
  }, []);
  const goToPreviousImage = useCallback(() => {
    const numImages = numImagesRef.current;
    setActiveIndex((index) => {
      const nextIndex = index - 1;
      if (nextIndex < 0) {
        return numImages - 1;
      }
      return nextIndex;
    });
  }, []);

  const onClickClose = useCallback(() => {
    window.sessionStorage.setItem('landingPage', 'seen');
    setShow(false);
  }, []);

  const [autoplay, setAutoplay] = useState(true);
  const autoplayRef = useRef(autoplay);
  autoplayRef.current = autoplay;
  const toggleAutoplay = useCallback(() => {
    const autoplay = autoplayRef.current;
    if (!autoplay) {
      goToNextImage();
    }
    setAutoplay((autoplay) => !autoplay);
  }, [goToNextImage]);

  useEffect(() => {
    const delay = app?.Config?.SlidesDelay;
    if (delay == null || !autoplay || !show) {
      return;
    }

    const timeoutId = setTimeout(() => {
      goToNextImage();
    }, delay);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [activeIndex, app?.Config?.SlidesDelay, autoplay, goToNextImage, show]);

  useEffect(() => {
    if (!show) {
      return;
    }

    const onKeyDown = (e: KeyboardEvent) => {
      if (e.code === 'ArrowRight') {
        goToNextImage();
      } else if (e.code === 'ArrowLeft') {
        goToPreviousImage();
      } else if (e.code === 'Space') {
        toggleAutoplay();
      }
    };

    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [goToNextImage, goToPreviousImage, show, toggleAutoplay]);

  return (
    <div className={cls.root} data-show={show}>
      <div
        className={cls.imagesContainer}
        title='Next image'
        onClick={toggleAutoplay}
        role='button'
      >
        {app?.Config.LandingPageImages.map((img, i) => {
          const isFirst = activeIndex === 0;
          const isLast = activeIndex === numImages - 1;
          const isActive = activeIndex === i;
          const isNextActive = isLast ? i === 0 : i === activeIndex + 1;
          const isPrevActive = isFirst
            ? i === numImages - 1
            : i === activeIndex - 1;

          // Only render and load currently active, next active and previously active image
          if (!isActive && !isNextActive && !isPrevActive) {
            return null;
          }

          return (
            <figure className={cls.figure} key={img.Id}>
              <Image
                className={cls.img}
                data-show={isActive}
                alt={img.Legend}
                pictureRef={img}
                sizes={sizes}
              />
            </figure>
          );
        })}
      </div>
      <div className={cls.buttons}>
        <button
          type='button'
          className={cls.closeButton}
          title='Close'
          onClick={onClickClose}
        >
          <img
            className={cls.closeIcon}
            src='/images/close-icon.svg'
            width='40'
            height='40'
            alt='Cross'
            aria-hidden
          />
        </button>
        {app?.Config.AudioFile?.Src && (
          <AudioPlayer
            className={cls.audioPlayer}
            muted={!show}
            src={app.Config.AudioFile.Src}
          />
        )}
      </div>
    </div>
  );
};

export default Intro;
