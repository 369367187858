import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import PageDetail from 'src/components/DetailPage';

const DetailPage: FC<PageDto> = ({ Text, MainText, UID, PageImages, Slides }) => {
  const location = useLocation();
  // Doing these because we know there are no route with more than 4 "/"...
  // ... only way to have the right UID for portal match
  const pathArr = location.pathname.split('/');
  const portalUID = pathArr.length === 5 ? pathArr[3] : UID;

  return <PageDetail images={PageImages} slides={Slides} portalUID={portalUID} text={Text} mainText={MainText} />;
};

export default DetailPage;
