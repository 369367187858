import { makeStyles } from '@material-ui/core/styles';
import mediaQueries from '../constants/mediaQueries';

export default makeStyles(
  () => ({
    audio: {
      opacity: 0,
      position: 'absolute',
      pointerEvents: 'none',
      width: 0,
      height: 0,
    },
    button: {
      display: 'flex',
      alignItem: 'center',
      justifyContent: 'center',
      padding: 16,
    },
    audioIcon: {
      width: 60,
      height: 60,
      [mediaQueries.Mobile]: {
        width: 40,
        height: 40,
      },
    },
  }),
  { name: 'Intro' }
);
