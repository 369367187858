import { makeStyles } from '@material-ui/core/styles';
import mediaQueries from '../constants/mediaQueries';

export default makeStyles(
  () => ({
    root: {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 99,
      opacity: 1,
      lineHeight: 0,

      transition: 'opacity .6s ease',
      backgroundColor: 'white',

      '&[data-show="false"]': {
        pointerEvents: 'none',
        opacity: 0,
      },
    },
    imagesContainer: {
      cursor: 'pointer',
    },
    figure: {},
    img: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
      opacity: 1,
      '&[data-show="false"]': {
        pointerEvents: 'none',
        opacity: 0,
      },
      '&[data-hide="true"]': {
        display: 'none',
      },
    },
    buttons: {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      alignItem: 'center',
      justifyContent: 'center',
      top: 42 - 16,
      right: 46 - 16,
      [mediaQueries.Mobile]: {
        top: 30 - 16,
        right: 30 - 16,
      },
    },
    closeButton: {
      padding: 16,
    },
    closeIcon: {
      width: 60,
      height: 60,
      [mediaQueries.Mobile]: {
        width: 40,
        height: 40,
      },
    },
    audioPlayer: {},
  }),
  { name: 'Intro' }
);
