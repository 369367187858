import { makeStyles } from '@material-ui/core/styles';
import fonts from 'src/constants/fonts';
import spacing from '../constants/spacing';
import mediaQueries from '../constants/mediaQueries';

export default makeStyles(
  () => ({
    root: {
      [mediaQueries.Mobile]: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,

        transition: 'transform .4s ease-in-out',
        transform: 'translateY(0%)',

        '&[data-open="true"]': {
          transform: 'translateY(100%)',
        },

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
      },
      [mediaQueries.AfterMobile]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        height: '100%',
        overflow: 'auto',
      },
      [mediaQueries.Tablet]: {
        padding: `0 ${spacing.Gutter.Tablet}px ${spacing.Gutter.Tablet}px ${spacing.Gutter.Tablet}px`,
      },
      [mediaQueries.Laptop]: {
        padding: `0 ${spacing.Gutter.Laptop}px ${spacing.Gutter.Laptop}px ${spacing.Gutter.Laptop}px`,
      },
      [mediaQueries.Desktop]: {
        padding: `0 ${spacing.Gutter.Desktop}px ${spacing.Gutter.Desktop}px ${spacing.Gutter.Desktop}px`,
      },
      [mediaQueries.BigDesktop]: {
        padding: `0 ${spacing.Gutter.BigDesktop}px ${spacing.Gutter.BigDesktop}px ${spacing.Gutter.BigDesktop}px`,
      },
    },
    langWrapper: {
      position: 'absolute',
      top: spacing.Gutter.Mobile,
      right: spacing.Gutter.Mobile,
    },
    langLink: {
      ...fonts.DTNobelCondBold,
      textTransform: 'uppercase',
      fontSize: '2rem',
    },
  }),
  { name: 'MainMenu' }
);
