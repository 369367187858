import * as React from 'react';
import {
  useEffect,
  useState,
  FC,
  useContext,
  createContext,
  useRef,
} from 'react';
import { Language } from 'src/utils/Languages';
import { useApi } from './Api';

export type IPage = IPageDto & { endpoint: string };
type TApp = {
  app?: AppDto;
  menuOpen: boolean;
  redirectionsMap: Map<string, string>;
  toggleMenu: React.Dispatch<boolean>;
};
export const AppContext = createContext<TApp>({} as never);
export const App: FC = ({ children }) => {
  const { get } = useApi();
  const [app, setApp] = useState<AppDto>();
  const [open, setOpen] = useState(false);
  const redirectionsMap = useRef(new Map<string, string>());

  useEffect(() => {
    (async () => {
      const appElt = document.getElementById('app');
      const json = appElt?.innerHTML;
      let dto: AppDto | undefined;

      try {
        dto = (json && (JSON.parse(json) as AppDto)) || undefined;
      } catch (e) {
        console.info('malformed JSON on page');
      }

      if (!dto) {
        dto = await get<AppDto>(`/${Language}/api/app`);
      }

      dto.MainMenu.forEach((item) => {
        if (item.Submenus.length) {
          redirectionsMap.current.set(
            `/${Language}/${item.UID}`,
            `${item.Url}`
          );
        }
      });

      setApp(dto);
    })();
  }, [setApp, get]);

  const value: TApp = {
    app,
    redirectionsMap: redirectionsMap.current,
    menuOpen: open,
    toggleMenu: setOpen,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useApp = (): TApp => useContext(AppContext);
