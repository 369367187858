import React, { FC } from 'react';
import CollectionItemPreview from 'src/components/CollectionItemPreview';
import useStyles from './Collection.styles';

const Collection: FC<CollectionDto> = ({ Previews }) => {
  const cls = useStyles();

  return (
    <div className={cls.root}>
      {Previews.map((p) => (
        <div key={p.UID} data-size={p.Size} className={cls.item}>
          <CollectionItemPreview {...p} />
        </div>
      ))}
    </div>
  );
};

export default Collection;
