/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { useApp } from 'src/@context/App';
import useStyles from './Burger.styles';

const Burger = () => {
  const cls = useStyles();
  const { menuOpen, toggleMenu } = useApp();

  return (
    <div className={cls.root} data-open={menuOpen}>
      <button
        className={cls.btn}
        type='button'
        onClick={() => toggleMenu(!menuOpen)}
      >
        <span className={cls.span} />
      </button>
    </div>
  );
};

export default React.memo(Burger);
