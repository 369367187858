export default {
	Gutter: {
		Mobile: 20,
		Tablet: 20,
		Laptop: 20,
		Desktop: 20,
		BigDesktop: 40,
	},
	HeaderHeight: {
		Mobile: 72,
		Tablet: 68,
		Laptop: 68,
		Desktop: 83,
		BigDesktop: 98,
	},
	MaxWebsiteWidth: 3000,
};
