import { makeStyles } from '@material-ui/core/styles';
import stylesheet from 'src/constants/stylesheet';
import mediaQueries from '../constants/mediaQueries';

export default makeStyles(
  () => ({
    root: {
      [mediaQueries.Mobile]: {},

      [mediaQueries.AfterMobile]: {},
    },
    item: {
      ...stylesheet.Submenu,
      position: 'relative',
      paddingLeft: 15,
      textTransform: 'uppercase',
    },
    itemActive: {
      '&:before': {
        content: '""',
        width: 9,
        height: 9,
        backgroundColor: 'currentColor',
        borderRadius: '50%',
        position: 'absolute',
        top: 'calc(50% - 3px)',
        left: 0,
      },
    },
    sideText: {
      hyphens: 'auto',
      flex: '1 1 auto',
      position: 'relative',

      '[data-active="true"] &': {
        // margin: '10px 0',
      },
      [mediaQueries.Mobile]: {
        display: 'none',
      },
    },
    sideTextInner: {
      height: '100%',
      paddingTop: 8,
      paddingBottom: 14,
      paddingLeft: 15,
    },
  }),
  { name: 'SubmenuItem' }
);
