import React from 'react';
import { Route, Redirect, useLocation, Switch } from 'react-router-dom';
import isTouchDevice from 'is-touch-device';
import mediaQueries from './constants/mediaQueries';
import Pages from './Pages';
import Header from './components/Header';
import MainMenu from './components/MainMenu';
import Intro from './components/Intro';
import { ResponsiveProvider } from './@context/Responsive';
import useStyles from './App.styles';
import { useApp } from './@context/App';

const App = (): JSX.Element => {
  const cls = useStyles();
  const isTouch = isTouchDevice();
  const { pathname } = useLocation();
  const { redirectionsMap } = useApp();

  return (
    <ResponsiveProvider mediaQueries={mediaQueries}>
      <Intro />
      <div className={cls.app} data-touch-device={isTouch}>
        <div className={cls.smallColumn}>
          <div className={cls.smallColumnInner}>
            <MainMenu />
          </div>
        </div>
        <main className={cls.bigColumn}>
          <div className={cls.header}>
            <Header />
          </div>
          <div className={cls.main}>
            <Switch>
              <Redirect from='/:url*(/+)' to={pathname.slice(0, -1)} />
              {redirectionsMap.has(pathname) && (
                <Redirect to={redirectionsMap.get(pathname)!} />
              )}
              <Route render={() => <Pages />} />
            </Switch>
          </div>
        </main>
      </div>
    </ResponsiveProvider>
  );
};

export default App;
