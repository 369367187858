import React, { FC } from 'react';
import PreviewItem from 'src/components/PreviewItem';
import useStyles from './Overview.styles';

const Overview: FC<OverviewDto> = ({ PagesPreview }) => {
  const cls = useStyles();

  return (
    <div className={cls.root}>
      {PagesPreview.map((p, i) => (
        <PreviewItem
          key={p.UID}
          title={p.Title}
          url={p.Url}
          UID={p.UID}
          previewImage={p.PreviewImage}
        />
      ))}
    </div>
  );
};

export default Overview;
