import { makeStyles } from '@material-ui/core/styles';
import spacing from 'src/constants/spacing';
import mediaQueries from '../constants/mediaQueries';

const useStyles = makeStyles(
  () => ({
    root: {
      display: 'grid',

      [mediaQueries.Mobile]: {
        gridTemplateColumns: '1fr',
        padding: `0 ${spacing.Gutter.Mobile / 2}px`,
        gap: `${spacing.Gutter.Mobile / 2}px`,
      },
      [mediaQueries.Tablet]: {
        gridTemplateColumns: '1fr',
        padding: `0 ${spacing.Gutter.Tablet}px`,
        gap: `${spacing.Gutter.Tablet}px`,
      },
      [mediaQueries.Laptop]: {
        gridTemplateColumns: '1fr 1fr',
        padding: `0 ${spacing.Gutter.Laptop}px`,
        gap: `${spacing.Gutter.Laptop}px`,
      },
      [mediaQueries.Desktop]: {
        gridTemplateColumns: '1fr 1fr',
        padding: `0 ${spacing.Gutter.Desktop}px`,
        gap: `${spacing.Gutter.Desktop}px`,
      },
      [mediaQueries.BigDesktop]: {
        gridTemplateColumns: '1fr 1fr',
        padding: `0 ${spacing.Gutter.BigDesktop}px`,
        gap: `${spacing.Gutter.BigDesktop}px`,
      },
    },
  }),
  { name: 'Overview' }
);

export default useStyles;
