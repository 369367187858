import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import React, { FC, useCallback, useState } from 'react';
import mapStyles from '../utils/mapStyles';
import icon from '../assets/icon.png';

const options: google.maps.MapOptions = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
  // gestureHandling: 'cooperative',
};

const Map: FC<{ coordinates: GeoPoint }> = ({ coordinates }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY || '',
  });

  const [map, setMap] = useState(null);

  const onLoad = useCallback(
    (m) => {
      // const bounds = new window.google.maps.LatLngBounds();
      // m.fitBounds(bounds);
      setMap(map);
    },
    [map]
  );

  const onUnmount = React.useCallback(() => setMap(null), []);

  if (!isLoaded) return <div>Loading</div>;
  return (
    <GoogleMap
      mapContainerStyle={{
        position: 'relative',
        width: '100%',
        height: '100%',
      }}
      center={{ lat: coordinates.lat, lng: coordinates.lng }}
      zoom={16}
      options={options}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      <Marker
        position={{ lat: coordinates.lat, lng: coordinates.lng }}
        icon={icon}
      />
    </GoogleMap>
  );
};

export default Map;
