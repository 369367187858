import { makeStyles } from '@material-ui/core/styles';
import fonts from 'src/constants/fonts';
import mediaQueries from 'src/constants/mediaQueries';
import spacing from 'src/constants/spacing';

export default makeStyles(
  () => ({
    root: {
      position: 'relative',
      [mediaQueries.Mobile]: {
        padding: `0 ${spacing.Gutter.Desktop / 2}px`,
      },
      [mediaQueries.AfterMobile]: {
        height: '100%',
      },
    },
    mobileWrapper: {
      display: 'flex',
      flexDirection: 'column',
      [mediaQueries.Tablet]: {
        padding: `0 ${spacing.Gutter.Tablet}px`,
      },
    },
    figure: {
      position: 'relative',
      marginBottom: spacing.Gutter.Mobile / 2,

      '&:first-child': {
        order: 0,
      },
      '&:nth-child(n+2)': {
        order: 2,
      },
    },
    img: {
      width: '100%',
      maxHeight: '100%',
    },
    content: {
      lineHeight: '1.3',
      textAlign: 'justify',
      hyphens: 'auto',

      [mediaQueries.Mobile]: {
        order: 1,
        marginBottom: spacing.Gutter.Mobile,
      },

      [mediaQueries.AfterMobile]: {
        height: 'auto',
      },

      [mediaQueries.BigDesktop]: {},
    },
    title: {
      ...fonts.DTNobelCondBold,
      display: 'none',
      [mediaQueries.AfterMobile]: {
        display: 'initial',
      },
    },
    text: {
      paddingRight: 5,

      [mediaQueries.Mobile]: {
        paddingTop: 10,
      },
    },
    pdfLink: {
      display: 'block',
      paddingTop: 5,
      ...fonts.DTNobelCondBold,

      [mediaQueries.BigDesktop]: {
        fontSize: '1.1rem',
      },
    },
    diaporamaContainerDesktop: {
      display: 'flex',
      flexDirection: 'column',
      maxHeight: 'calc(100vh - var(--headerHeight))',
      width: 'fit-content',
      '--headerHeight': `${spacing.HeaderHeight.Desktop}px`,
      [mediaQueries.Desktop]: {
        '--headerHeight': `${spacing.HeaderHeight.Desktop}px`,
      },
      [mediaQueries.BigDesktop]: {
        '--headerHeight': `${spacing.HeaderHeight.BigDesktop}px`,
      },
      [mediaQueries.Mobile]: {
        padding: `0 ${spacing.Gutter.Mobile}px`,
      },
      [mediaQueries.Tablet]: {
        padding: `0 ${spacing.Gutter.Tablet}px`,
      },
      [mediaQueries.Laptop]: {
        padding: `0 ${spacing.Gutter.Laptop}px`,
      },
      [mediaQueries.Desktop]: {
        padding: `0 ${spacing.Gutter.Desktop}px`,
      },
      [mediaQueries.BigDesktop]: {
        padding: `0 ${spacing.Gutter.BigDesktop}px`,
      },
    },
    mainTextContainer: {
      textAlign: 'center',
      position: 'relative',
    },
    mainText: {
      marginTop: '2rem',
      lineHeight: '1.3',
      hyphens: 'auto',
      width: '100%',
      fontSize: '1.5rem',
      fontFamily: 'DTNobelLight, Times New Roman',
      textAlign: 'left',
      [mediaQueries.Mobile]: {
        padding: `0 ${spacing.Gutter.Mobile}px`,
      },
      [mediaQueries.Tablet]: {
        padding: `0 ${spacing.Gutter.Tablet}px`,
      },
      [mediaQueries.Laptop]: {
        padding: `0 ${spacing.Gutter.Laptop}px`,
      },
      [mediaQueries.Desktop]: {
        padding: `0 ${spacing.Gutter.Desktop}px`,
      },
      [mediaQueries.BigDesktop]: {
        padding: `0 ${spacing.Gutter.BigDesktop}px`,
        fontSize: '1.8rem',
      },
      [mediaQueries.AfterTablet]: {
        opacity: 0,
        transition: 'opacity 300ms',
        '&[data-showText="true"]': {
          opacity: 1,
        },
      },
    },
    downButtonContainer: {
      display: 'none',
      [mediaQueries.AfterTablet]: {
        display: 'block',
      },
      position: 'absolute',
      top: '-2rem',
      left: 0,
      right: 0,
      textAlign: 'center',
      flexShrink: 0,
      transition: 'opacity 300ms',
      zIndex: 1,
      '&[data-hidden="true"]': {
        opacity: 0,
        pointerEvents: 'none',
      },
    },
    downButton: {
      margin: '0 auto',
      padding: '1rem 2rem',
      ...fonts.DTNobelCondBold,
      fontSize: '0.87rem',
      textTransform: 'uppercase',
      display: 'inline-flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 6,
    },
    upButtonContainer: {
      marginTop: '1rem',
      textAlign: 'center',
    },
    upButton: {
      margin: '0 auto',
      padding: '1rem 2rem',
    },
  }),
  { name: 'DetailPage' }
);
