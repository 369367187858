import classNames from 'classnames';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useApp } from 'src/@context/App';
import { useResponsive } from 'src/@context/Responsive';
import { Languages } from '../utils/Languages';
import AnimateHeightChange from './AnimateHeightChange';
import useStyles from './MenuItem.styles';
import SubmenuItem from './SubmenuItem';

const MenuItem: FC<MenuDto & { isFirst: boolean }> = ({
  Title,
  Text,
  Url,
  Submenus,
  UID,
  isFirst,
}) => {
  const cls = useStyles();
  const location = useLocation();
  const { steps } = useResponsive();
  const { menuOpen, toggleMenu } = useApp();

  const pathSegments = location.pathname.split('/');
  const lastSegment = pathSegments[pathSegments.length - 1];

  const isActiveAsFirstItem = useMemo(() => {
    return (
      isFirst &&
      (location.pathname === '/' ||
        Languages.map((lang) => `/${lang}`).includes(location.pathname))
    );
  }, [isFirst, location.pathname]);
  const isActive = useMemo(() => {
    return (
      isActiveAsFirstItem ||
      pathSegments.includes(UID) ||
      Submenus.some((menu) => pathSegments.includes(menu.UID))
    );
  }, [Submenus, UID, isActiveAsFirstItem, pathSegments]);
  const [expanded, setExpanded] = useState(isActive);

  useEffect(() => {
    setExpanded(isActive);
  }, [isActive]);

  const underlineMenuItem = isActive;

  return (
    <div className={cls.root} data-active={expanded}>
      {steps.includes('Mobile') && !!Submenus.length ? (
        <button
          type='button'
          className={classNames(cls.item, {
            [cls.itemActive]: underlineMenuItem,
          })}
          onClick={() => setExpanded(!expanded)}
        >
          {Title}
        </button>
      ) : (
        <NavLink
          className={classNames(cls.item, {
            [cls.itemActive]: underlineMenuItem,
          })}
          to={Url}
          activeClassName={cls.itemActive}
          onClick={() => {
            if (menuOpen) {
              toggleMenu(false);
            }
          }}
        >
          {Title}
        </NavLink>
      )}

      {!steps.includes('Mobile') && Text && lastSegment === UID && (
        <div className={cls.sideText}>
          <div
            className={cls.sideTextInner}
            dangerouslySetInnerHTML={{ __html: Text }}
          />
        </div>
      )}

      {!!Submenus.length && (
        <AnimateHeightChange expanded={expanded}>
          <nav className={cls.submenuWrapper}>
            {Submenus.map((s, i) => (
              <SubmenuItem
                key={s.Url}
                {...s}
                forceActive={i === 0 && isActiveAsFirstItem}
              />
            ))}
          </nav>
        </AnimateHeightChange>
      )}
    </div>
  );
};

export default MenuItem;
