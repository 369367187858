import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import useStyles from './VideoEmbed.styles'

type TVideoEmbed = {
	slide: DiaporamaItemVideoDto
	// eslint-disable-next-line react/require-default-props
	forceStop?: boolean
}

const VideoEmbed = ({ slide, forceStop = false }: TVideoEmbed) => {
	const cls = useStyles()

	let src = ''
	if (slide.Video.Provider === 'youtube') {
		src = `https://www.youtube.com/embed/${slide.Video.Id}`
	} else if (slide.Video.Provider === 'vimeo') {
		src = `https://player.vimeo.com/video/${slide.Video.Id}`
	}

	const [loadSrc, setLoadSrc] = useState(true)
	useEffect(() => {
		if (forceStop) {
			const timeoutId = setTimeout(() => {
				setLoadSrc(false)
			}, 300)
			return () => {
				clearTimeout(timeoutId)
			}
		}
	}, [forceStop])
	useEffect(() => {
		if (!loadSrc) {
			const timeoutId = setTimeout(() => {
				setLoadSrc(true)
			}, 300)
			return () => {
				clearTimeout(timeoutId)
			}
		}
	}, [loadSrc])

	return (
		<div
			className={classNames(cls.root, {
				[cls['root--widescreen']]: !slide.AspectRatio || slide.AspectRatio === 'widescreen',
				[cls['root--sdtv']]: slide.AspectRatio === 'sdtv',
			})}
		>
			<iframe
				title="Video"
				className={cls.iframe}
				src={loadSrc ? src : undefined}
				frameBorder="0"
				allowFullScreen
			/>
		</div>
	)
}

export default React.memo(VideoEmbed)
