import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useResponsive } from 'src/@context/Responsive';
import { Link } from 'react-router-dom';
import { Language } from 'src/utils/Languages';
import useStyles from './Header.styles';
import logo from '../assets/logo.png';
import logoMobile from '../assets/logo_mobile.png';
import Burger from './Burger';
import MainMenu from './MainMenu';

const Header = () => {
  const cls = useStyles();
  const { steps } = useResponsive();

  const [headerHidden, setHeaderHidden] = useState(false);
  const headerRef = useRef<HTMLDivElement>(null);
  const lastScrollTop = useRef(0);
  const headerHeight = useRef(0);
  const scroller = useRef(document.scrollingElement);
  const delta = 10;

  useEffect(() => {
    headerHeight.current = headerRef.current!.clientHeight;
  }, [headerRef]);

  const onScroll = useCallback(() => {
    const scrollTop =
      (scroller?.current?.scrollTop ?? 0) + document.body.scrollTop;

    if (Math.abs(lastScrollTop.current - scrollTop) <= delta) {
      return;
    }

    const $headerHidden =
      scrollTop > lastScrollTop.current && scrollTop > headerHeight.current;
    setHeaderHidden($headerHidden);

    lastScrollTop.current = scrollTop;
  }, [headerHeight, scroller]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [scroller, onScroll]);

  return (
    <header className={cls.root} ref={headerRef} data-hidden={headerHidden} id="header">
      <div className={cls.burger}>
        <Burger />
      </div>
      <figure className={cls.figure}>
        {steps.includes('Mobile') && (
          <img
            className={cls.logoImage}
            width='378'
            height='73'
            src={logoMobile}
            alt='Inchfurniture logo'
          />
        )}
        {steps.includes('AfterMobile') && (
          <img className={cls.logoImage} src={logo} alt='Inchfurniture logo' />
        )}
        <Link to='/' className={cls.logoLink} />
      </figure>
      <div className={cls.portal} id='header-portal' />
      <div className={cls.langSwitch}>
        <a
          aria-label='Switch lamguage'
          href='?switch-lang=de'
          className={cls.langLink}
          data-active={Language === 'de'}
        >
          De
        </a>
        <a
          aria-label='Switch lamguage'
          href='?switch-lang=en'
          className={cls.langLink}
          data-active={Language === 'en'}
        >
          En
        </a>
      </div>
      {steps.includes('Mobile') && (
        <div className={cls.mobileMenu}>
          <MainMenu />
        </div>
      )}
    </header>
  );
};

export default React.memo(Header);
