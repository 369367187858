import { makeStyles } from '@material-ui/core/styles';
import fonts from 'src/constants/fonts';
import spacing from 'src/constants/spacing';
import mediaQueries from '../constants/mediaQueries';

export default makeStyles(
  () => ({
    root: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      display: 'flex',
      backgroundColor: 'rgba(255, 255, 255, 0)',

      transition: 'transform linear 0.3s',
      transform: 'translateY(0)',

      '&[data-hidden="true"]': {
        transform: 'translateY(-130%)',
      },

      [mediaQueries.Mobile]: {
        justifyContent: 'center',
        padding: `${spacing.Gutter.Mobile}px`,
      },

      [mediaQueries.AfterMobile]: {
        alignItems: 'flex-end',
      },
      [mediaQueries.Tablet]: {
        padding: `${spacing.Gutter.Tablet}px ${spacing.Gutter.Tablet}px ${
          spacing.Gutter.Tablet / 2
        }px ${spacing.Gutter.Tablet}px`,
      },
      [mediaQueries.Laptop]: {
        height: spacing.HeaderHeight.Laptop,
        padding: `${spacing.Gutter.Laptop}px ${spacing.Gutter.Laptop}px ${
          spacing.Gutter.Laptop / 2
        }px ${spacing.Gutter.Laptop}px`,
      },
      [mediaQueries.Desktop]: {
        height: spacing.HeaderHeight.Desktop,
        padding: `${spacing.Gutter.Desktop}px ${spacing.Gutter.Desktop}px ${
          spacing.Gutter.Desktop / 2
        }px ${spacing.Gutter.Desktop}px`,
      },
      [mediaQueries.BigDesktop]: {
        height: spacing.HeaderHeight.BigDesktop,
        padding: `${spacing.Gutter.BigDesktop}px ${
          spacing.Gutter.BigDesktop
        }px ${spacing.Gutter.BigDesktop / 2}px ${spacing.Gutter.BigDesktop}px`,
      },
    },
    portal: {
      marginLeft: 'auto',
      [mediaQueries.Mobile]: {
        display: 'none',
      },
    },
    burger: {
      [mediaQueries.Mobile]: {
        position: 'absolute',
        top: '50%',
        left: 0,
        transform: 'translateY(-50%)',
        zIndex: 1,
        marginLeft: `${spacing.Gutter.Mobile / 2}px`,
      },
      [mediaQueries.AfterMobile]: {
        display: 'none',
      },
    },
    figure: {
      flexShrink: 0,
      position: 'relative',
      lineHeight: '0',

      [mediaQueries.Mobile]: {
        width: 100,
      },
      [mediaQueries.AfterMobile]: {
        width: 200,
        justifySelf: 'cente',
      },
    },
    logoImage: {
      width: '100%',
      height: '100%',
    },
    langSwitch: {
      [mediaQueries.Mobile]: {
        display: 'none',
      },
      [mediaQueries.AfterMobile]: {},
    },
    logoLink: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    },
    langLink: {
      marginLeft: 7,
      opacity: 0.6,
      textTransform: 'uppercase',

      '&[data-active="true"]': {
        opacity: 1,
        ...fonts.DTNobelCondBold,
      },
    },
    mobileMenu: {
      [mediaQueries.Mobile]: {
        position: 'absolute',
        right: 0,
        left: 0,
        height: '100vh',
        bottom: '100%',
      },

      [mediaQueries.AfterMobile]: {
        display: 'none',
      },
    },
  }),
  { name: 'Header' }
);
