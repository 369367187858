/* eslint-disable consistent-return */
import React, { useState, useLayoutEffect, useRef, FC } from 'react';

const AnimateHeightChange: FC<{
  expanded: boolean;
  children: React.ReactNode;
}> = ({ expanded, children, ...rest }) => {
  const [duration, setDuration] = useState(200);
  const disabled = window.matchMedia('(prefers-reduced-motion: reduce)')
    .matches;
  const domNodeRef = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState<string | number>('auto');

  useLayoutEffect(() => {
    if (disabled) {
      return;
    }

    const domNode = domNodeRef.current;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const resizeObserver = new ResizeObserver((entries) => {
      const rect = entries[0].contentRect;

      setHeight(rect.height);
      setDuration(rect.height * 1.3);
    });

    if (domNode) {
      resizeObserver.observe(domNode);

      return () => {
        resizeObserver.unobserve(domNode);
      };
    }
  }, [disabled]);

  return (
    <div
      className='AnimateHeightChange'
      style={{
        // eslint-disable-next-line no-nested-ternary
        height: disabled ? 'auto' : expanded ? height : 0,
        transition: `height ${duration}ms ease-out`,
        overflowY: 'hidden',
      }}
      {...rest}
    >
      <div className='inner' ref={domNodeRef}>
        {children}
      </div>
    </div>
  );
};

export default AnimateHeightChange;
