/* eslint-disable consistent-return */
/* eslint-disable react/no-danger */
import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useResponsive } from 'src/@context/Responsive';
import useStyles from './DetailPage.styles';
import Diaporama from './Diaporama';
import Image from './Image';
import VideoEmbed from './VideoEmbed';

type TDetailPage = {
  title?: string;
  images: PictureRef[];
  slides: IDto[];
  text?: string;
  mainText?: string;
  portalUID?: string;
  place?: string;
  year?: string;
  pdf?: FileRef;
};

const DetailPage: FC<TDetailPage> = ({
  images,
  slides,
  text,
  mainText,
  portalUID,
  title,
  place,
  year,
  pdf,
}) => {
  const cls = useStyles();
  const { steps } = useResponsive();

  const sizes = `(max-width: 768px) calc(100vw - 20px),
	(max-width: 1024px) calc(100vw - 300px),
	(max-width: 1280px) calc(100vw - 350px),
	(max-width: 1920px) calc(100vw - 400px),
	(max-width: 3000px) 2100px`;

  const onClickUp = useCallback(() => {
    window.scroll({ top: 0, behavior: 'smooth' });
  }, []);

  const mainTextRef = useRef<HTMLDivElement | null>(null);

  const onClickDown = useCallback(() => {
    const mainMenuEl = document.getElementById('main-menu');
    const mainTextEl = mainTextRef.current;
    if (!mainMenuEl || !mainTextEl) {
      window.scroll({ top: window.innerHeight, behavior: 'smooth' });
    } else {
      window.scroll({
        top:
          mainTextEl.getBoundingClientRect().top -
          mainMenuEl.getBoundingClientRect().top,
        behavior: 'smooth',
      });
    }
  }, []);

  const rootRef = useRef<HTMLDivElement | null>(null);
  const [pageIsScrollable, setPageIsScrollable] = useState(false);
  useEffect(() => {
    const onResize = () => {
      setPageIsScrollable(
        document.documentElement.scrollHeight >
          document.documentElement.clientHeight
      );
    };
    onResize();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const resizeObserver = new ResizeObserver(onResize);
    window.addEventListener('resize', onResize);
    if (rootRef.current) {
      resizeObserver.observe(rootRef.current);
    }
    return () => {
      window.removeEventListener('resize', onResize);
      resizeObserver.disconnect();
    };
  }, []);
  const [downButtonHidden, setDownButtonHidden] = useState(pageIsScrollable);
  useEffect(() => {
    const onScroll = () => {
      setDownButtonHidden(
        !pageIsScrollable || (document?.scrollingElement?.scrollTop || 0) > 10
      );
    };
    onScroll();
    document.addEventListener('scroll', onScroll);
    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, [pageIsScrollable]);

  const [containerWidth, setContainerWidth] = useState<string | number>('100%');
  const [
    diaporamaContainerEl,
    setDiaporamaContainerEl,
  ] = useState<HTMLDivElement | null>(null);
  const calculateContainerSize = useCallback(() => {
    if (!diaporamaContainerEl) {
      return;
    }
    const header = document.getElementById('header');
    const width = Math.floor(diaporamaContainerEl.offsetWidth);
    // Hack: Due to CSS layout weirdness (or my incompetence), the
    // images initially don't take up the correct amount of space.
    // So if the container is smaller than 1/4 of the window, we assume
    // it's the first render.
    if (width < 0.25 * window.innerWidth) {
      return;
    }
    if (header) {
      header.style.maxWidth = `${width}px`;
    }
    setContainerWidth(width);
  }, [diaporamaContainerEl]);
  useLayoutEffect(() => {
    if (steps.includes('AfterTablet')) {
      calculateContainerSize();
    }
  }, [calculateContainerSize, steps]);
  useEffect(() => {
    if (
      !diaporamaContainerEl ||
      images.length + slides.length === 0 ||
      !steps.includes('AfterTablet')
    ) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const observer = new ResizeObserver(calculateContainerSize);
    observer.observe(diaporamaContainerEl);

    return () => {
      observer.disconnect();

      const header = document.getElementById('header');
      if (header) {
        header.style.maxWidth = '';
      }
    };
  }, [
    calculateContainerSize,
    diaporamaContainerEl,
    images.length,
    slides.length,
    steps,
  ]);

  return (
    <article className={cls.root} ref={rootRef}>
      {/* Tablet Layout */}
      {steps.includes('Mobile') && (
        <div className={cls.mobileWrapper}>
          {images.length > 0 &&
            images.map((img) => (
              <figure className={cls.figure} key={img.Id}>
                <Image alt={img.Legend} pictureRef={img} sizes={sizes} />
              </figure>
            ))}
          {slides.map((slide, i) => {
            if (slide.type === 'DiaporamaItemImage') {
              const img = (slide as DiaporamaItemImageDto).Image;

              return (
                <figure className={cls.figure} key={img.Id}>
                  <Image alt={img.Legend} pictureRef={img} sizes={sizes} />
                </figure>
              );
            }

            if (slide.type === 'DiaporamaItemVideo') {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div className={cls.figure} key={i}>
                  <VideoEmbed slide={slide as DiaporamaItemVideoDto} />
                </div>
              );
            }

            return null;
          })}
          <div className={cls.content}>
            <h2 className={cls.title}>{title}</h2>
            {(place || year) && (
              <h3 className={cls.title}>{`${place}, ${year}`}</h3>
            )}
            {text && (
              <div
                dangerouslySetInnerHTML={{ __html: text }}
                className={cls.text}
              />
            )}
            {pdf && (
              <a
                href={pdf.Src}
                className={cls.pdfLink}
                target='_blank'
                rel='noopener noreferrer'
              >
                PDF MAGAZIN
              </a>
            )}
          </div>
        </div>
      )}
      {/* Tablet Layout */}
      {steps.includes('Tablet') && (
        <>
          <div className={cls.mobileWrapper}>
            {images.length > 0 &&
              images.map((img) => (
                <figure className={cls.figure} key={img.Id}>
                  <Image alt={img.Legend} pictureRef={img} sizes={sizes} />
                </figure>
              ))}
            {slides.map((slide, i) => {
              if (slide.type === 'DiaporamaItemImage') {
                const img = (slide as DiaporamaItemImageDto).Image;

                return (
                  <figure className={cls.figure} key={img.Id}>
                    <Image alt={img.Legend} pictureRef={img} sizes={sizes} />
                  </figure>
                );
              }

              if (slide.type === 'DiaporamaItemVideo') {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <div className={cls.figure} key={i}>
                    <VideoEmbed slide={slide as DiaporamaItemVideoDto} />
                  </div>
                );
              }

              return null;
            })}
          </div>
        </>
      )}

      {/* AfterTablet Layout */}
      {steps.includes('AfterTablet') && images.length + slides.length > 0 && (
        <>
          <div
            className={cls.diaporamaContainerDesktop}
            ref={setDiaporamaContainerEl}
          >
            <Diaporama images={images} slides={slides} />
          </div>
        </>
      )}
      {mainText && (
        <div
          className={cls.mainTextContainer}
          ref={mainTextRef}
          style={diaporamaContainerEl ? { maxWidth: containerWidth } : {}}
        >
          <div
            className={cls.downButtonContainer}
            data-hidden={downButtonHidden}
          >
            <button
              type='button'
              className={cls.downButton}
              title='Scroll down to text'
              onClick={onClickDown}
            >
              Kontext
              <img
                src='/images/arrow-down.svg'
                width='40'
                height='15'
                alt='Arrow pointing downwards'
                aria-hidden
              />
            </button>
          </div>
          <div
            className={cls.mainText}
            dangerouslySetInnerHTML={{ __html: mainText }}
            data-showText={downButtonHidden}
          />
          {pageIsScrollable && (
            <div className={cls.upButtonContainer}>
              <button
                type='button'
                className={cls.upButton}
                title='Go back up'
                onClick={onClickUp}
              >
                <img
                  src='/images/arrow-up.svg'
                  width='40'
                  height='15'
                  alt='Arrow pointing upwards'
                  aria-hidden
                />
              </button>
            </div>
          )}
        </div>
      )}
    </article>
  );
};

export default DetailPage;
