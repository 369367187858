import mediaQueries from './mediaQueries';
import fonts from './fonts';

export const bodyFontSize = {
	Mobile: 14,
	Tablet: 14,
	Laptop: 16,
	Desktop: 16,
	BigDesktop: 18,
};

export const imageRatio = 66.68;

export default {
	Text: {
		lineHeight: '1.25',
    ...fonts.DTNobelCondRegular,
		[mediaQueries.Mobile]: {
			fontSize: bodyFontSize.Mobile,
		},
		[mediaQueries.Tablet]: {
			fontSize: bodyFontSize.Tablet,
		},
		[mediaQueries.Laptop]: {
			fontSize: bodyFontSize.Laptop,
		},
		[mediaQueries.Desktop]: {
			fontSize: bodyFontSize.Desktop,
		},
		[mediaQueries.BigDesktop]: {
			fontSize: bodyFontSize.BigDesktop,
		},
	},
	ObjectFitCover: {
		objectFit: 'cover',
		fontFamily: '"object-fit: cover;"',
	},
	ObjectFitContain: {
		objectFit: 'contain',
		fontFamily: '"object-fit: contain;"',
	},
	Menu: {
		...fonts.DTNobelCondBold,
		fontSize: '2.5rem',
		display: 'block',
		lineHeight: 1.15,

		[mediaQueries.Mobile]: {
			fontSize: '3.4rem',
			textAlign: 'center',
		},
		[mediaQueries.Tablet]: {
			fontSize: '2.3rem',
		},
		[mediaQueries.Laptop]: {
			fontSize: '2.3rem',
		},
	},
	Submenu: {
		...fonts.DTNobelCondBold,
		fontSize: '1.4rem',
		display: 'block',

		[mediaQueries.Mobile]: {
			fontSize: '1.6rem',
			textAlign: 'center',
		},
		[mediaQueries.Tablet]: {
			fontSize: '1.2rem',
		},
		[mediaQueries.Laptop]: {
			fontSize: '1.2rem',
		},
	},
};
