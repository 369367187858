import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  () => ({
    root: {
      overflow: 'hidden',
      width: '100%',
      height: '100%',
      backgroundColor: 'black',
    },
    'root--widescreen': {
      aspectRatio: '16 / 9',
    },
    'root--sdtv': {
      aspectRatio: '3 / 2',
    },
    iframe: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  }),
  { name: 'VideoEmbed' }
);
