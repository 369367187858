import { createStyles, makeStyles } from '@material-ui/core/styles';
import fonts from './constants/fonts';
import mediaQueries from './constants/mediaQueries';
import spacing from './constants/spacing';
import stylesheet from './constants/stylesheet';

export default makeStyles(
  () =>
    createStyles({
      '@global': {
        'html, body': {
          position: 'relative',
          width: '100%',
          height: '100%',
          '-webkit-font-smoothing': 'antialiased!important',
          '-moz-osx-font-smoothing': 'grayscale',
          ...stylesheet.Text,
        },
        '#root': {
          position: 'relative',
          width: '100%',
          height: '100%',
        },
        a: {
          textDecoration: 'none',
          color: 'inherit',
        },
        strong: {
          ...fonts.DTNobelCondBold,
        },
      },
      app: {
        [mediaQueries.Mobile]: {
          padding: 0,
        },
        [mediaQueries.AfterMobile]: {
          display: 'flex',
          minHeight: '100%',
          maxWidth: spacing.MaxWebsiteWidth,
          margin: '0 auto',
        },
        [mediaQueries.Tablet]: {
          padding: `0 ${spacing.Gutter.Tablet * 1}px 0 ${
            spacing.Gutter.Tablet * 1
          }px`,
        },
        [mediaQueries.Laptop]: {
          padding: `0 ${spacing.Gutter.Laptop * 2}px 0 ${
            spacing.Gutter.Laptop * 1
          }px`,
        },
        [mediaQueries.Desktop]: {
          padding: `0 ${spacing.Gutter.Desktop * 5}px 0 ${
            spacing.Gutter.Desktop * 3
          }px`,
        },
        [mediaQueries.BigDesktop]: {
          padding: `0 ${spacing.Gutter.BigDesktop * 5}px 0 ${
            spacing.Gutter.BigDesktop * 3
          }px`,
        },
      },
      smallColumn: {
        [mediaQueries.Mobile]: {
          display: 'none',
        },
        [mediaQueries.AfterMobile]: {
          flex: '0 0 400px',
        },
        [mediaQueries.Tablet]: {
          flex: '0 0 300px',
        },
        [mediaQueries.Laptop]: {
          flex: '0 0 350px',
        },
        [mediaQueries.BigDesktop]: {
          flex: '0 0 440px',
        },
      },
      smallColumnInner: {
        [mediaQueries.Mobile]: {},
        [mediaQueries.AfterMobile]: {
          position: 'sticky',
          top: 0,
          height: '100vh',
        },
        [mediaQueries.Tablet]: {
          paddingTop: spacing.HeaderHeight.Tablet,
        },
        [mediaQueries.Laptop]: {
          paddingTop: spacing.HeaderHeight.Laptop,
        },
        [mediaQueries.Desktop]: {
          paddingTop: spacing.HeaderHeight.Desktop,
        },
        [mediaQueries.BigDesktop]: {
          paddingTop: spacing.HeaderHeight.BigDesktop,
        },
      },
      bigColumn: {
        [mediaQueries.Mobile]: {},
        [mediaQueries.AfterMobile]: {
          flex: '1 1 77%',

          display: 'flex',
          flexDirection: 'column',
        },
        [mediaQueries.Tablet]: {
          flex: '1 1 65%',
          paddingBottom: `${spacing.Gutter.Tablet}px`,
        },
        [mediaQueries.Laptop]: {
          flex: '1 1 70%',
          paddingBottom: `${spacing.Gutter.Laptop}px`,
        },
        [mediaQueries.Desktop]: {
          paddingBottom: `${spacing.Gutter.Desktop}px`,
        },
        [mediaQueries.BigDesktop]: {
          paddingBottom: `${spacing.Gutter.BigDesktop}px`,
        },
      },
      header: {
        position: 'sticky',
        top: 0,
        zIndex: 10,
        [mediaQueries.Mobile]: {},

        [mediaQueries.AfterMobile]: {},
      },
      main: {
        position: 'relative',

        [mediaQueries.Mobile]: {
          paddingTop: spacing.HeaderHeight.Mobile,
        },

        [mediaQueries.AfterMobile]: {
          flex: '1',
        },

        [mediaQueries.Tablet]: {
          paddingTop: spacing.HeaderHeight.Tablet,
        },

        [mediaQueries.Laptop]: {
          paddingTop: spacing.HeaderHeight.Laptop,
        },

        [mediaQueries.Desktop]: {
          paddingTop: spacing.HeaderHeight.Desktop,
        },
        [mediaQueries.BigDesktop]: {
          paddingTop: spacing.HeaderHeight.BigDesktop,
        },
      },
    }),
  { name: 'App' }
);
