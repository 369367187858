import { makeStyles } from '@material-ui/core';
import mediaQueries from 'src/constants/mediaQueries';
import spacing from 'src/constants/spacing';

const useStyles = makeStyles(
  () => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      margin: '-0.5vw',

      [mediaQueries.Mobile]: {
        padding: `0 ${spacing.Gutter.Mobile}px`,
        margin: 0,
        gap: spacing.Gutter.Mobile,
      },
      [mediaQueries.Tablet]: {
        padding: `0 ${spacing.Gutter.Tablet}px`,
      },
      [mediaQueries.Laptop]: {
        padding: `0 ${spacing.Gutter.Laptop}px`,
      },
      [mediaQueries.Desktop]: {
        padding: `0 ${spacing.Gutter.Desktop}px`,
      },
      [mediaQueries.BigDesktop]: {
        padding: `0 ${spacing.Gutter.BigDesktop}px`,
      },
    },
    item: {
      flex: '0 0 auto',
      padding: '0.5vw',

      [mediaQueries.Mobile]: {
        width: '100%',
        padding: 0,
      },
      [mediaQueries.Tablet]: {
        width: `50%`,
      },
      [mediaQueries.AfterTablet]: {
        width: `50%`,
      },
      [mediaQueries.Desktop]: {
        '&[data-size="full"]': {
          width: '100%',
        },
        '&[data-size="half"]': {
          width: '50%',
        },

        '&[data-size="third"]': {
          width: `${100 / 3}%`,
        },

        '&[data-size="fourth"]': {
          width: `${100 / 4}%`,
        },

        '&[data-size="fifth"]': {
          width: `${100 / 5}%`,
        },

        '&[data-size="sixth"]': {
          width: `${100 / 6}%`,
        },
      },
      [mediaQueries.BigDesktop]: {
        '&[data-size="full"]': {
          width: '100%',
        },
        '&[data-size="half"]': {
          width: '50%',
        },

        '&[data-size="third"]': {
          width: `${100 / 3}%`,
        },

        '&[data-size="fourth"]': {
          width: `${100 / 4}%`,
        },

        '&[data-size="fifth"]': {
          width: `${100 / 5}%`,
        },

        '&[data-size="sixth"]': {
          width: `${100 / 6}%`,
        },
      },
    },
    content: {
      lineHeight: '1.3',
      textAlign: 'justify',
      hyphens: 'auto',

      [mediaQueries.Mobile]: {
        order: 1,
        marginBottom: spacing.Gutter.Mobile,
      },

      [mediaQueries.AfterMobile]: {
        height: 'auto',
      },

      [mediaQueries.BigDesktop]: {},
    },
    text: {
      paddingRight: 5,

      [mediaQueries.Mobile]: {
        paddingTop: 10,
      },
    },
  }),
  { name: 'Collection' }
);

export default useStyles;
