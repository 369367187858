type TCDN = {
	url: string;
	formats?: { [key: string]: Array<{ name: string; width: number }> };
};
export const images: TCDN = {
	url: process.env.REACT_APP_CDN_URL!,
	formats: {
		default: [
			{ name: 'normal_thumb', width: 300 },
			{ name: 'normal_small', width: 600 },
			{ name: 'normal_medium', width: 900 },
			{ name: 'normal_large', width: 1200 },
			{ name: 'normal_xlarge', width: 1800 },
			{ name: 'normal_xxlarge', width: 2000 },
			{ name: 'normal_xxxlarge', width: 2200 },
			{ name: 'normal_big', width: 2400 },
			{ name: 'normal_huge', width: 3000 },
		],
	},
};

export default {
	images,
};
