import { makeStyles } from '@material-ui/core/styles';
import mediaQueries from 'src/constants/mediaQueries';
import stylesheet from 'src/constants/stylesheet';

export default makeStyles(
  () => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [mediaQueries.AfterMobile]: {
        flex: '0 0 auto',
        overflow: 'hidden',
        alignItems: 'flex-start',
      },
    },
    item: {
      ...stylesheet.Menu,
      textTransform: 'uppercase',
      color: 'inherit',
      position: 'relative',
      [mediaQueries.AfterMobile]: {
        flex: '0 0 auto',
      },
    },
    itemActive: {
      '&:after': {
        content: '""',
        position: 'absolute',
        bottom: -4,
        left: 0,
        right: 0,
        borderBottom: '5px solid currentColor',
      },
    },
    submenuWrapper: {
      paddingTop: 14,
      paddingBottom: 6,
      height: 'auto',
      display: 'flex',
      overflow: 'hidden',
      flexDirection: 'column',
      [mediaQueries.Mobile]: {
        alignItems: 'center',
      },
    },
    sideText: {
      hyphens: 'auto',
      flex: '1 1 auto',
      position: 'relative',
    },
    sideTextInner: {
      height: '100%',
      paddingTop: 10,
      paddingBottom: 10,
    },
  }),
  { name: 'MenuItem' }
);
