import React, { FC } from 'react';
import DetailPage from 'src/components/DetailPage';
import { useLocation } from 'react-router-dom';
import { useApp } from 'src/@context/App';

const Project: FC<ProjectDto> = ({
  Title,
  Text,
  MainText,
  ProjectImages,
  Slides,
  Place,
  Year,
  PDF,
}) => {
  const { app } = useApp();
  const location = useLocation();
  const pathnameArr = location.pathname.split('/');
  const menuUID = pathnameArr[pathnameArr.length - 2];

  if (!app) {
    return null;
  }

  return (
    <DetailPage
      images={ProjectImages}
      slides={Slides}
      portalUID={menuUID}
      text={Text}
      mainText={MainText}
      title={Title}
      place={Place}
      year={Year}
      pdf={PDF}
    />
  );
};

export default Project;
