import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  () => ({
    root: {
      position: 'relative',
      width: 40,
      height: 40,
    },
    btn: {
      width: '100%',
      height: '100%',
    },
    span: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      height: 2,
      width: 30,
      marginTop: '-1px',
      transform: 'translateX(-50%)',

      backgroundColor: '#000',
      transition: 'background 0.2s',

      '&:before': {
        content: '""',
        position: 'absolute',
        display: 'block',
        left: 0,
        width: '100%',
        backgroundColor: '#000',
        height: 2,
        top: '-7px',

        transitionDuration: '0.2s, 0.2s',

        transitionProperty: 'top, transform',

        transitionDelay: '0.2s, 0s',

        transitionTimingFunction: 'cubic-bezier(0.04, 0.04, 0.12, 0.96)',
      },

      '&:after': {
        content: '""',
        position: 'absolute',
        display: 'block',
        left: 0,
        width: '100%',
        backgroundColor: '#000',
        height: 2,
        bottom: '-7px',

        transitionDuration: '0.2s, 0.2s',
        transitionProperty: 'bottom, transform',
        transitionDelay: '0.2s, 0s',
        transitionTimingFunction: 'cubic-bezier(0.04, 0.04, 0.12, 0.96)',
      },

      '[data-open="true"] &': {
        backgroundColor: 'transparent',
      },

      '[data-open="true"] &:before': {
        top: 0,
        transform: 'rotate(45deg)',
        transitionDelay: '0s, 0.2s',
      },

      '[data-open="true"] &:after': {
        bottom: 0,
        transform: 'rotate(-45deg)',
        transitionDelay: '0s, 0.2s',
      },
    },
  }),
  { name: 'Burger' }
);
