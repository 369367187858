import { makeStyles } from '@material-ui/core/styles';
import fonts from 'src/constants/fonts';
import { imageRatio } from 'src/constants/stylesheet';

export default makeStyles(
  () => ({
    root: {
      position: 'relative',
      height: 0,
      paddingBottom: `${imageRatio}%`,
    },
    inner: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    title: {
      position: 'absolute',
      top: 0,
      left: 0,

      ...fonts.DTNobelCondBold,
      textTransform: 'uppercase',
      zIndex: 1,
      transition: 'opacity .3s ease',

      '[data-touch-device="false"] $root &': {
        opacity: 0,
      },
      '[data-touch-device="false"] $root:hover &': {
        opacity: 1,
      },

      '& span': {
        backgroundColor: 'white',
      },
    },
    figure: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,

      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        objectPosition: 'center',
      },
    },
    link: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
  }),
  { name: 'CollectionItemPreview' }
);
