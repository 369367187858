import { FC } from 'react';

const Projects: FC<ProjectsDto> = () => {
  // return (
  //   <div className={cls.root}>
  //     {previews.map((p, i) => (
  //       <PreviewItem
  //         key={p.UID}
  //         title={p.Title}
  //         url={p.Url}
  //         UID={p.UID}
  //         previewImage={p.PreviewImage}
  //         year={p.Year}
  //       />
  //     ))}
  //   </div>
  // );

  return null;
};

export default Projects;
