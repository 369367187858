import React, { FC } from 'react';
import PreviewItem from 'src/components/PreviewItem';
import useStyles from './ProjectsCategory.styles';

const ProjectsCategory: FC<ProjectsCategoryDto> = ({ Projects }) => {
  const cls = useStyles();

  return (
    <>
      <div className={cls.root}>
        {Projects.map((p, i) => (
          <div key={p.UID} data-size={p.Size || 'third'} className={cls.item}>
            <PreviewItem
              key={p.UID}
              title={p.Title}
              url={p.Url}
              UID={p.UID}
              previewImage={p.PreviewImage}
              year={p.Year}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default ProjectsCategory;
