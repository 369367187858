// export const mobileSize = 479;
export const mobileSize = 768;
export const tabletSize = 1024;
export const laptopSize = 1280;
export const desktopSize = 1920;

const mediaQueries = {
	Landscape: `@media screen and  (orientation: landscape)`,
	Portrait: `@media screen and  (orientation: portrait)`,
	Mobile: `@media screen and (max-width: ${mobileSize}px)`,
	AfterMobile: `@media screen and (min-width: ${mobileSize + 1}px)`,
	Tablet: `@media screen and (min-width: ${
		mobileSize + 1
	}px) and (max-width: ${tabletSize}px)`,
	AfterTablet: `@media screen and (min-width: ${tabletSize + 1}px)`,
	Laptop: `@media screen and (min-width: ${
		tabletSize + 1
	}px) and (max-width: ${laptopSize}px)`,
	Desktop: `@media screen and (min-width: ${
		laptopSize + 1
	}px) and (max-width: ${desktopSize}px)`,
	BigDesktop: `@media screen and (min-width: ${desktopSize + 1}px)`,
};

export default mediaQueries;
