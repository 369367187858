import { makeStyles } from '@material-ui/core';
import fonts from 'src/constants/fonts';
import mediaQueries from 'src/constants/mediaQueries';
import spacing from 'src/constants/spacing';
import { imageRatio } from 'src/constants/stylesheet';

export default makeStyles(
  () => ({
    root: {
      position: 'relative',
      [mediaQueries.Mobile]: {
        padding: `0 ${spacing.Gutter.Desktop / 2}px`,
      },
      [mediaQueries.AfterMobile]: {
        height: '100%',
      },
      [mediaQueries.Tablet]: {
        padding: `0 ${spacing.Gutter.Tablet}px`,
      },
      [mediaQueries.Laptop]: {
        padding: `0 ${spacing.Gutter.Laptop}px`,
      },
      [mediaQueries.Desktop]: {
        padding: `0 ${spacing.Gutter.Desktop}px`,
      },
      [mediaQueries.BigDesktop]: {
        padding: `0 ${spacing.Gutter.BigDesktop}px`,
      },
    },
    mobileWrapper: {
      position: 'relative',
      height: 0,
      paddingBottom: `${imageRatio}%`,
      overflow: 'hidden',
      marginBottom: spacing.Gutter.Mobile,
    },
    mobileWrapperInner: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    figure: {
      position: 'relative',
      marginBottom: spacing.Gutter.Mobile / 2,

      '&:first-child': {
        order: 0,
      },
      '&:nth-child(n+2)': {
        order: 2,
      },
    },
    img: {
      width: '100%',
      maxHeight: '100%',
    },
    content: {
      lineHeight: '1.3',

      [mediaQueries.Mobile]: {
        order: 1,
        marginBottom: spacing.Gutter.Mobile,
      },

      [mediaQueries.AfterMobile]: {
        height: '100%',
      },

      [mediaQueries.BigDesktop]: {},
    },
    title: {
      ...fonts.DTNobelCondBold,
    },
    text: {
      paddingTop: 5,
      paddingRight: 5,

      [mediaQueries.Mobile]: {
        paddingTop: 10,
      },
    },
    pdfLink: {
      display: 'block',
      paddingTop: 5,
      ...fonts.DTNobelCondBold,

      [mediaQueries.BigDesktop]: {
        fontSize: '1.1rem',
      },
    },
  }),
  { name: 'Contact' }
);
