export default {
  DTNobelCondBold: {
    fontFamily: 'DTNobelCondBold',
    fontWeight: 700,
    fontStretch: 'normal',
    fontStyle: 'normal',
  },
  DTNobelCondRegular: {
    fontFamily: 'DTNobelCondRegular',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
  },
  DTNobelLight: {
    fontFamily: 'DTNobelLight',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
  },
};
