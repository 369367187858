import * as React from "react";
import { useContext, useState, createContext } from "react";
import { Language } from "src/utils/Languages";

type Api = {
  get: <TData extends unknown = IPageDto>(path: string) => Promise<TData>;
  loading: boolean;
};

export const ApiContext = createContext<Api>({} as never);

export const Api: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const origin = process.env.REACT_APP_API_URL?.replace(/\/+$/g, "");

  const requestOptions: RequestInit = {
    method: "GET",
    headers: {
      "Accept-Language": Language,
      "Content-Type": "application/json",
    },
  };

  const fetchPage = async <TData extends unknown = IPageDto>(
    url: string
  ): Promise<TData> => {
    const [path, query] = url.split("?");

    return fetch(
      `${origin}${path}?${query ? `${query}&json` : "json"}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          return { type: response.status.toString(), response };
        }
        return response.json();
      })
      .then((pageDto) => {
        setLoading(false);
        return pageDto;
      })
      .catch((e) => {
        console.error(`Api: Error while loading: ${origin}${url}?json, ${e}`);
        setLoading(false);
      });
  };

  return (
    <ApiContext.Provider value={{ get: fetchPage, loading }}>
      {children}
    </ApiContext.Provider>
  );
};

export const useApi = (): Api => useContext(ApiContext);
