import { makeStyles } from '@material-ui/core/styles';
import arrowNext from '../assets/arrow-right.png';
import arrowNextCur from '../assets/arrow-right.cur';
import arrowPrevious from '../assets/arrow-left.png';
import arrowPreviousCur from '../assets/arrow-left.cur';

export default makeStyles(
  () => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '100%',
    },
    content: {
      position: 'relative',
      flex: '1',
    },
    figure: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      flex: 1,

      opacity: 0,
      willChange: 'opacity',
      transition: 'opacity .3s ease',
      pointerEvents: 'none',

      '&[data-active="true"]': {
        opacity: 1,
        pointerEvents: 'initial',
        width: '100%',
      },
      '&[data-type="video"]': {
        width: '100%',
        maxWidth: '100%',
      },
      '& img': {
        width: '100%',
        height: 'auto',
        aspectRatio: '3 / 2',
        maxWidth: '100%',
        maxHeight: 'calc(90vh - 80px)',
      },
      '& img[data-orientation="portrait"]': {
        height: 'auto',
        width: 'auto',
        maxHeight: 'calc(90vh - 80px)',
        maxWidth: '100%',
      },
    },
    img: {},
    btn: {
      position: 'absolute',
      top: 120,
      bottom: 120,
      width: '25%',
    },
    previous: {
      left: 0,
      '&': {
        cursor: `url(${arrowPrevious}),e-resize`,
      },
      cursor: `url(${arrowPreviousCur}) 19 28 ,e-resize`,
    },
    next: {
      right: 0,
      '&': {
        cursor: `url(${arrowNext}),e-resize`,
      },
      cursor: `url(${arrowNextCur}) 19 28 ,e-resize`,
    },
    nav: {
      flex: '0 0 40px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    navItem: {
      flex: '0 0 auto',
      margin: 5,
      width: 7,
      height: 7,
      borderRadius: '100%',
      backgroundColor: 'gray',

      '&[data-active="true"], [data-touch-device="false"] &:hover': {
        backgroundColor: 'black',
      },
    },
  }),
  { name: 'Diaporama' }
);
