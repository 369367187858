import React, { FC } from 'react';
import { useResponsive } from 'src/@context/Responsive';
import useStyles from './Contact.styles';
import Map from '../components/Map';

const Contact: FC<ContactDto> = ({ Text, Coordinates }) => {
  const cls = useStyles();
  const { steps } = useResponsive();

  return (
    <article className={cls.root}>
      {/* Tablet Layout */}
      {steps.includes('Mobile') && (
        <>
          <div className={cls.mobileWrapper}>
            <div className={cls.mobileWrapperInner}>
              <Map coordinates={Coordinates} />
            </div>
          </div>

          <div className={cls.content}>
            {Text && (
              <div
                dangerouslySetInnerHTML={{ __html: Text }}
                className={cls.text}
              />
            )}
          </div>
        </>
      )}

      {/* AfterTablet Layout */}
      {steps.includes('AfterMobile') && (
        <div
          style={{
            position: 'relative',
            width: '100%',
            height: '100%',
          }}
        >
          <Map coordinates={Coordinates} />
        </div>
      )}
    </article>
  );
};

export default Contact;
