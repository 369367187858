import classNames from 'classnames';
import React, { FC, useEffect, useRef, useState } from 'react';
import ReactDom from 'react-dom';
import useStyles from './Diaporama.styles';
import Image from './Image';
import VideoEmbed from './VideoEmbed';
import spacerImage from '../assets/diaporama-spacer-image.png';

type TDiaporama = {
  images: PictureRef[];
  slides: IDto[];
};

const Diaporama: FC<TDiaporama> = ({ images, slides }) => {
  const cls = useStyles();
  const [current, setCurrent] = useState(0);
  const numSlidesTotal = images.length + slides.length;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const [navPortalEl] = useState(
    () => document.getElementById('header-portal')!
  );

  const go = (value: number) => setCurrent(value);
  const previous = () => {
    const prev = current - 1 < 0 ? numSlidesTotal - 1 : current - 1;
    go(prev);
  };
  const next = () => {
    const $next = (current + 1) % numSlidesTotal;
    go($next);
  };

  const sizes = `(max-width: 768px) calc(100vw - 20px),
	(max-width: 1024px) calc(100vw - 300px),
	(max-width: 1280px) calc(100vw - 350px),
	(max-width: 1920px) calc(100vw - 400px),
	(max-width: 3000px) 2100px`;

  const containerRef = useRef<HTMLDivElement | null>(null);
  const [isVisible, setIsVisible] = useState(true);
  useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        setIsVisible(entry.isIntersecting);
      });
    });
    observer.observe(container);

    // eslint-disable-next-line consistent-return
    return () => {
      observer.disconnect();
    };
  }, []);

  if (images.length + slides.length === 0) {
    return null;
  }

  return (
    <div className={cls.root} ref={containerRef}>
      <article className={cls.content}>
        {/* Trick: Use a transparent image to size the container properly. It should always be at aspect-ratio 3/2.
            We depend on the size of the images to set the size of the container.
            However: if an image doesn't load, it will not push the width of its parent. That's why we use an base64-encoded
            image which will always load.
            */}
        <div
          className={cls.figure}
          data-active='true'
          style={{ position: 'relative', pointerEvents: 'none' }}
        >
          <img
            src={spacerImage}
            width='3000'
            height='2000'
            data-orientation='portrait'
            data-active
            className={cls.img}
            style={{ position: 'relative' }}
            alt=''
            aria-hidden
          />
        </div>
        {images.map((img, i) => {
          const orientation = img.Width > img.Height ? 'landscape' : 'portrait';

          return (
            <figure
              key={img.Id}
              data-active={i === current}
              className={cls.figure}
            >
              <Image
                alt={img.Legend}
                pictureRef={img}
                sizes={sizes}
                className={cls.img}
                data-orientation={orientation}
                width={img.Width}
                height={img.Height}
              />
            </figure>
          );
        })}
        {slides.map((slide, i) => {
          if (slide.type === 'DiaporamaItemVideo') {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div
                className={cls.figure}
                key={i}
                data-type='video'
                data-active={i + images.length === current}
              >
                <VideoEmbed
                  slide={slide as DiaporamaItemVideoDto}
                  forceStop={i + images.length !== current}
                />
              </div>
            );
          }

          if (slide.type === 'DiaporamaItemImage') {
            const img = (slide as DiaporamaItemImageDto).Image;
            if (!img) {
              return null;
            }
            const orientation =
              img.Width > img.Height ? 'landscape' : 'portrait';

            return (
              <figure
                key={img.Id}
                data-active={i + images.length === current}
                className={cls.figure}
              >
                <Image
                  alt={img.Legend}
                  pictureRef={img}
                  sizes={sizes}
                  className={cls.img}
                  data-orientation={orientation}
                  width={img.Width}
                  height={img.Height}
                />
              </figure>
            );
          }

          return null;
        })}
        {numSlidesTotal > 1 && (
          <>
            <button
              type='button'
              className={classNames(cls.btn, cls.previous)}
              aria-label='Previous image'
              onClick={previous}
            />
            <button
              type='button'
              className={classNames(cls.btn, cls.next)}
              aria-label='Next image'
              onClick={next}
            />
          </>
        )}
      </article>
      {numSlidesTotal > 1 &&
        isVisible &&
        ReactDom.createPortal(
          <nav className={cls.nav}>
            {Array.from({ length: numSlidesTotal }).map((_, i) => (
              <button
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                type='button'
                className={cls.navItem}
                aria-label={`Image number ${i}`}
                data-active={i === current}
                onClick={() => go(i)}
              />
            ))}
          </nav>,
          navPortalEl
        )}
    </div>
  );
};

export default Diaporama;
