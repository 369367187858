import React, { useEffect, useCallback, useState, useRef } from 'react';
import DocumentMeta from 'react-document-meta';
import useReactRouter from 'use-react-router';
import anime from 'animejs';
import ReactGA from 'react-ga';
import { useApi } from './@context/Api';
import Projects from './Pages/Projects';
import Project from './Pages/Project';
import Furniture from './Pages/Furniture';
import DetailPage from './Pages/DetailPage';
import Overview from './Pages/OverView';
import Collection from './Pages/Collection';
import CollectionItem from './Pages/CollectionItem';
import Contact from './Pages/Contact';
import { IPage, useApp } from './@context/App';
import ProjectsCategory from './Pages/ProjectsCategory';

type TTransition = 'fade' | 'swipe' | 'none';

const transitions = {
  swipe: [
    {
      translateY: '100%',
      opacity: 1,
    },
    {
      translateY: 0,
      duration: 1000,
      easing: 'cubicBezier(0.8, 0, 0.2, 1)',
    },
    {
      translateY: '-100%',
      duration: 1000,
      easing: 'cubicBezier(0.8, 0, 0.2, 1)',
    },
  ],
  fade: [
    {
      translateY: 0,
      opacity: 0,
    },
    {
      opacity: [0, 1],
      duration: 200,
      easing: 'easeOutSine',
    },
    {
      opacity: [1, 0],
      duration: 200,
      easing: 'easeOutSine',
    },
  ],
};

const Pages = () => {
  const { get } = useApi();
  const { app } = useApp();
  const [page, setPage] = useState<IPage>();
  // const { pathname } = useLocation();
  const loadingContainer = useRef<HTMLDivElement>(null);

  const { location, history } = useReactRouter<any, any, { t?: TTransition }>();
  const [transition, setTransition] = useState<TTransition>('fade');
  const url = useRef(location.pathname);

  const fetchPage = useCallback(async () => {
    const endpoint = location.pathname + location.search;
    const $stateTransition = location.state?.t || 'fade';
    const isPush = history.action === 'PUSH';
    const pageChanged = endpoint !== url.current;

    let $transition: TTransition;
    if (pageChanged) {
      $transition = isPush ? $stateTransition : transition;
    } else {
      $transition = 'none';
    }

    setTransition($stateTransition);
    if ($transition !== 'none') {
      const [init, animIn, animOt] = transitions[$transition] || [];
      anime.set(loadingContainer.current, init);

      const [, nextPage] = await Promise.all([
        anime({
          targets: loadingContainer.current,
          ...animIn,
        }).finished,
        get(endpoint),
      ]);

      setPage({ ...nextPage, endpoint });

      await anime({
        targets: loadingContainer.current,
        ...animOt,
      }).finished;
    } else {
      const $page = await get(endpoint);
      setPage({ ...$page, endpoint });
    }
    url.current = location.pathname + location.search;
  }, [
    get,
    history.action,
    location.pathname,
    location.search,
    transition,
    location.state,
  ]);

  useEffect(() => {
    fetchPage();
  }, [location.pathname, location.search, location.state, history, fetchPage]);

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 100);
  }, [location.pathname]);

  useEffect(() => {
    if (!app || !app.Config) {
      return;
    }

    const config = app.Config;
    if (!config.GoogleAnalyticsCode) {
      return;
    }

    ReactGA.initialize(`${config.GoogleAnalyticsCode}`);
    ReactGA.pageview(window.location.pathname);
  }, [page, app]);

  if (!app) {
    return null;
  }

  return (
    <>
      {page && app && (
        <DocumentMeta
          title={page.MetaTitle || app.Config.MetaTitle || ''}
          // description={page.MetaDescription || app.Config.MetaDescription || ''}
        />
      )}

      {/* <div ref={loadingContainer} className={cls.loadingContainer} /> */}

      {page && page.type === 'Home' && <div>Home</div>}
      {page && page.type === 'Projects' && (
        <Projects {...((page as IPageDto) as ProjectsDto)} />
      )}

      {page && page.type === 'Project' && (
        <Project {...((page as IPageDto) as ProjectDto)} />
      )}

      {page && page.type === 'Furniture' && (
        <Furniture {...((page as IPageDto) as FurnitureDto)} />
      )}
      {page && page.type === 'Page' && (
        <DetailPage {...((page as IPageDto) as PageDto)} />
      )}
      {page && page.type === 'Overview' && (
        <Overview {...((page as IPageDto) as OverviewDto)} />
      )}
      {page && page.type === 'Collection' && (
        <Collection {...((page as IPageDto) as CollectionDto)} />
      )}
      {page && page.type === 'CollectionItem' && (
        <CollectionItem {...((page as IPageDto) as CollectionItemDto)} />
      )}
      {page && page.type === 'Contact' && (
        <Contact {...((page as IPageDto) as ContactDto)} />
      )}
      {page && page.type === 'ProjectsCategory' && (
        <ProjectsCategory {...((page as IPageDto) as ProjectsCategoryDto)} />
      )}
    </>
  );
};

export default Pages;
